.support {
    margin-top: 3rem;
    font-size: 1.6rem;
    font-weight: 700;
}

.cryptoAddress {
    font-size: 3rem;
    word-wrap: break-word;
}

.cryptoAddress > a {
    color: var(--polka-dot-color);
}
