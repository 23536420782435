.header {
    font-size: 6rem;
}

.description {
    font-size: 1.9rem;
    font-weight: 100;
    padding: 0.5rem 0;
}

.logo {
    width: 2.3rem;
    height: 2.3rem;
    vertical-align: bottom;
}

.polkadot {
    color: var(--polka-dot-color);
}
