.App {
    font-family: 'Roboto', sans-serif;
    position: relative;
    min-height: 100vh;
}

html {
    overflow-x: hidden;
}


.chart {
    height: 20vh;
    width: 100vw;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.05;
}


.middleScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: inherit;
}

.middleScreen > div {
    font-size: 5rem;
    text-transform: uppercase;
    font-weight: 700;
}
