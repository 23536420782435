

.authForm {
    min-height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
}


.loginButton {
    margin-top: 1rem;
    width: min(30rem, 90%);
    height: 3rem;
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    border: none;
    border-radius: 0.5rem;
    background-color: var(--main-color);
    color: white;
    cursor: pointer;
}
