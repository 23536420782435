.assets {
    font-size: 2.3rem;
    display: grid;
    grid-template-columns: max-content auto;
    grid-column-gap: 1rem;
    font-weight: 300;
    width: 50%;
    max-width: 800px;
}

.assets > div:nth-child(2n) {
    justify-self: end;
}

.isPositive {
    color: green;
}

.isNegative {
    color: red;
}

.coinbase {
    color: var(--coinbase-color);
}

.coinbase:link, .coinbase:focus, .coinbase:visited {
    text-decoration: none;
}

.includeDonations {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 1rem;
}

@media (max-width: 768px) {
    .assets {
        font-size: 1.7rem;
        grid-row-gap: 0;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .assets {
        font-size: 1.4rem;
    }

    .includeDonations {
        width: 1.2rem;
        height: 1.2rem;
        margin-left: 0.5rem;
    }
}
