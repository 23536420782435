

.page {
    padding: 2rem;
}

.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
}

.header {
    font-size: 5rem;
    text-transform: uppercase;
}

.assets {
    padding-bottom: 2rem;
}


.logo {
    width: 7rem;
    height: 7rem;
    background-image: url("../../polkadot.svg");
    background-repeat: no-repeat;
}


@media screen and (max-width: 768px) {
    .page {
        padding: 1.5rem;
    }

    .header {
        font-size: 3.5rem;
    }
}
